import {createWebHistory, createRouter} from 'vue-router';

export const routes = [
  {
    name: 'maps-overview',
    path: '/',
    meta: {sidebar: true},
    component: () => import('./pages/OverviewV2.vue'),
  },
  {
    name: 'maps-preview',
    path: '/preview/:id',
    meta: {sidebar: true},
    component: () => import('./pages/Preview.vue'),
  },
  {
    name: 'maps-edit',
    path: '/edit/:id?',
    meta: {sidebar: true},
    component: () => import('./pages/Edit.vue'),
  },
  {
    name: 'maps-create',
    path: '/create',
    meta: {sidebar: true},
    component: () => import('./pages/Edit.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
