<template>
  <MapStepBase
    :items="items"
    :title="T('MAPS_STEP_MARKERS_TITLE')"
    :zoom="() => flyToToolType(LayerGroupType.Marker)"
    :add="() => addMarker({draggedMarker: false, customPosition: {}})"
    :zoom-group-tooltip="T('MAPS_STEP_MARKERS_ZOOMTO_ALL')"
    :zoom-tooltip="T('MAPS_STEP_MARKERS_ZOOMTO')"
    :add-tooltip="T('MAPS_STEP_MARKERS_ADD')"
    :delete-tooltip="T('MAPS_STEP_MARKERS_BUTTON_DELETE')"
    :visibility-tooltip="T('MAPS_STEP_MARKERS_VISIBILITY')"
    :item-placeholder-title="T('MAPS_STEP_MARKERS_TITLE_NAME')"
    :item-icon="
      item =>
        item.options.iconPath ? availableIcons.find(x => `./${x.fileName}.png` == item.options.iconPath).key : 'map'
    "
  >
    <template v-for="(marker, index) in items" #[`item-${index}`] :key="index">
      <XGrid columns="2">
        <XInput
          :placeholder="T('MAPS_STEP_MARKERS_PLACEHOLDER_NAME')"
          :modelValue="marker.options.name"
          @update:modelValue="setLayerOptions({id: marker.id, name: $event})"
        />
        <XInput
          :placeholder="T('MAPS_STEP_MARKERS_PLACEHOLDER_DESCRIPTION')"
          :modelValue="marker.options.description"
          @update:modelValue="setLayerOptions({id: marker.id, description: $event})"
        />
        <XSelect
          :clearable="true"
          :options="[
            {label: T('MAPS_STEP_MARKERS_LABEL_CAR'), value: './Car.png'},
            {label: T('MAPS_STEP_MARKERS_LABEL_BIKE'), value: './Bike.png'},
            {label: T('MAPS_STEP_MARKERS_LABEL_WALK'), value: './Walk.png'},
            {label: T('MAPS_STEP_MARKERS_LABEL_HOME'), value: './House.png'},
            {
              label: T('MAPS_STEP_MARKERS_LABEL_STORE'),
              value: './shops_all.png',
            },
          ]"
          :placeholder="T('MAPS_STEP_MARKERS_PLACEHOLDER_MARKER_TYPE')"
          :modelValue="marker.options.iconPath"
          @update:modelValue="setLayerOptions({id: marker.id, iconPath: $event})"
        />
        <XSelect
          :clearable="true"
          :options="[
            {
              label: T('MAPS_STEP_MARKERS_LABEL_SIZE_SMALL'),
              value: IconSize.small,
              order: 0,
            },
            {
              label: T('MAPS_STEP_MARKERS_LABEL_SIZE_MEDIUM'),
              value: IconSize.medium,
              order: 1,
            },
            {
              label: T('MAPS_STEP_MARKERS_LABEL_SIZE_LARGE'),
              value: IconSize.large,
              order: 2,
            },
          ]"
          :placeholder="T('MAPS_STEP_MARKERS_PLACEHOLDER_SIZE')"
          :modelValue="marker.options.iconSize"
          @update:modelValue="setLayerOptions({id: marker.id, iconSize: $event})"
        />
        <XColorPicker
          :label="T('MAPS_STEP_CIRCLES_LABEL_COLOR')"
          :modelValue="marker.options.fillColor"
          @change="setLayerOptions({id: marker.id, fillColor: $event})"
        />
      </XGrid>
    </template>
  </MapStepBase>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import {IconSize} from '../models';
import {LayerGroupType} from '../models';
import MapStepBase from './MapStepBase.vue';
import {T} from '@dnx/core';

export default {
  name: 'MapStepMarkers',
  props: {
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    return {
      availableIcons: [
        {
          key: 'car',
          translationKey: 'MAPS_STEP_MARKERS_LABEL_CAR',
          fileName: 'Car',
        },
        {
          key: 'bike',
          translationKey: 'MAPS_STEP_MARKERS_LABEL_BIKE',
          fileName: 'Bike',
        },
        {
          key: 'walk',
          translationKey: 'MAPS_STEP_MARKERS_LABEL_WALK',
          fileName: 'Walk',
        },
        {
          key: 'house',
          translationKey: 'MAPS_STEP_MARKERS_LABEL_HOME',
          fileName: 'House',
        },
        {
          key: 'shopping-cart',
          translationKey: 'MAPS_STEP_MARKERS_LABEL_STORE',
          fileName: 'shops_all',
        },
      ],
    };
  },
  data() {
    return {
      LayerGroupType,
      IconSize,
    };
  },
  created() {
    this.T = T;
  },
  methods: {
    ...mapActions({
      flyToToolType: 'maps/flyToToolType',
    }),
    ...mapMutations({
      setLayerOptions: 'maps/setLayerOptions',
      addMarker: 'maps/addMarker',
    }),
  },
  components: {MapStepBase},
};
</script>
