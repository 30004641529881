<template>
  <MapStepBase
    :items="items"
    :title="T('MAPS_STEP_AREAS_STATISTICS')"
    :zoom="() => flyToToolType(LayerGroupType.Area)"
    :add="
      () => {
        addArea();
        triggerStatisticsFetchRequest();
      }
    "
    :zoom-group-tooltip="T('MAPS_STEP_AREAS_ZOOMTO_ALL')"
    :zoom-tooltip="T('MAPS_STEP_AREA_ZOOMTO')"
    :add-tooltip="T('MAPS_STEP_AREAS_ADD')"
    :delete-tooltip="T('MAPS_STEP_AREAS_DELETE_BUTTON')"
    :visibility-tooltip="T('MAPS_STEP_AREAS_VISIBILITY')"
    :item-placeholder-title="T('MAPS_STEP_AREAS_STATISTICS_NAME')"
    item-icon="chart"
  >
    <template v-for="(item, index) in items" #[`item-${index}`]>
      <XInput
        :placeholder="T('MAPS_STEP_AREAS_NAME_PLACEHOLDER')"
        customWidth="18rem"
        :modelValue="area.options.name"
        @update:modelValue="setLayerOptions({id: area.id, name: $event})"
      />
      <XInput
        :placeholder="T('MAPS_STEP_AREAS_PLACEHOLDER_DESCRIPTION')"
        customWidth="18.45rem"
        :modelValue="area.options.description"
        @update:modelValue="setLayerOptions({id: area.id, description: $event})"
      />
      <XButton component-style="primary-action" size="small" @click="addAreas()">
        <XIcon name="plus-circle" size="custom" />
        {{ T('MAPS_STEP_AREAS_CHOOSE_STATISTIC') }}
      </XButton>

      <XButton
        component-style="primary-action"
        size="small"
        @click="
          fetchAreasGeoJson({
            layer: area,
            geb_year: 2018,
            geb_type: 'gemeente',
            geb_code_array: ['GM0014'],
            pv_code_array: ['pv20'],
          })
        "
      >
        <XIcon name="redo-arrow" size="custom" />
        {{ T('MAPS_STEP_AREAS_LOAD_STATISTIC') }}
      </XButton>
    </template>
  </MapStepBase>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import {LayerGroupType} from '../models';
import MapStepBase from './MapStepBase.vue';
import {T} from '@dnx/core';

export default {
  name: 'MapStepAreas',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalStatus: false,
      LayerGroupType,
    };
  },
  created() {
    this.T = T;
  },
  methods: {
    ...mapMutations('maps', ['setLayerOptions', 'setGroupVisibility', 'addArea', 'removeLayerGroup']),
    ...mapActions('maps', ['flyToGroup', 'fetchAreasGeoJson', 'flyToToolType', 'fetchAllStatistics']),
    addAreas() {
      this.modalStatus = !this.modalStatus;
    },
    triggerStatisticsFetchRequest() {
      if (!this.allStatistics) {
        // fetch the statistics only one time
        this.fetchAllStatistics();
      }
    },
  },
  components: {
    MapStepBase,
  },
};
</script>
