<template>
  <MapStepBase
    :items="items"
    :title="T('MAPS_STEP_CUSTOMERDATA_TITLE')"
    :zoom="() => flyToToolType(LayerGroupType.CustomerData)"
    :add="() => addCustomerData()"
    :zoom-group-tooltip="T('MAP_STEP_CUSTOMERDATA_ZOOMTO_ALL')"
    :zoom-tooltip="T('MAP_STEP_CUSTOMERDATA_ZOOMTO')"
    :add-tooltip="T('MAP_STEP_CUSTOMERDATA_ADD')"
    :delete-tooltip="T('MAP_STEP_CUSTOMERDATA_DELETE_BUTTON')"
    :visibility-tooltip="T('MAP_STEP_CUSTOMERDATA_VISIBILITY')"
    :item-placeholder-title="T('MAP_STEP_CUSTOMERDATA_TITLE')"
  >
    <template v-for="(customerDataLayer, index) in items" #[`item-${index}`]>
      <XGrid columns="2">
        <XInput
          :placeholder="T('MAP_STEP_CUSTOMERDATA_CLIENTNAME')"
          :modelValue="customerDataLayer.options.name"
          @update:modelValue="setLayerOptions({id: customerDataLayer.id, name: $event})"
        />
        <XInput
          :placeholder="T('MAP_STEP_CUSTOMERDATA_CLIENT_DESCRIPTION')"
          :modelValue="customerDataLayer.options.description"
          @update:modelValue="setLayerOptions({id: customerDataLayer.id, description: $event})"
        />

        <XSelect
          :clearable="true"
          :options="[
            {label: T('MAP_STEP_CUSTOMERDATA_LOAD_CUSTOMERS'), value: 'customers'},
            {label: T('MAP_STEP_CUSTOMERDATA_LOAD_ORDERS'), value: 'orders'},
            {label: T('MAP_STEP_CUSTOMERDATA_LOAD_BRANCHES'), value: 'branches'},
          ]"
          :placeholder="T('MAPS_STEP_CUSTOMERDATA_PLACEHOLDER_MARKER_TYPE')"
          :modelValue="customerDataLayer.options.customerDataType"
          @update:modelValue="
            setLayerOptions({id: customerDataLayer.id, customerDataType: $event}),
              (selectedCustomerDataType[customerDataLayer.id] = $event)
          "
        />
      </XGrid>
    </template>
  </MapStepBase>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import {IconSize} from '../models';
import {LayerGroupType} from '../models';
import MapStepBase from './MapStepBase.vue';
import {T} from '@dnx/core';

export default {
  name: 'MapStepCustomerData',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      layers: [],
      customerDataLayerIsVisible: {},
      LayerGroupType,
      IconSize,
      selectedCustomerDataType: {},
      openToggle: false,
    };
  },
  created() {
    this.T = T;
  },
  methods: {
    ...mapActions({
      flyToToolType: 'maps/flyToToolType',
    }),
    ...mapMutations({
      setLayerOptions: 'maps/setLayerOptions',
      addCustomerData: 'maps/addCustomerData',
    }),
  },
  components: {MapStepBase},
};
</script>
