<template>
  <MapStepBase
    :items="items"
    :title="T('MAPS_STEP_CIRCLES_TITLE')"
    :zoom="() => flyToToolType(LayerGroupType.Circle)"
    :add="() => addCircle()"
    :zoom-group-tooltip="T('MAPS_STEP_CIRCLES_ZOOMTO_ALL')"
    :zoom-tooltip="T('MAPS_STEP_CIRCLES_ZOOMTO')"
    :add-tooltip="T('MAPS_STEP_CIRCLES_ADD')"
    :delete-tooltip="T('MAPS_STEP_CIRCLES_DELETE_BUTTON')"
    :visibility-tooltip="T('MAPS_STEP_CIRCLES_LABEL_VISIBILTY')"
    :item-placeholder-title="T('MAPS_STEP_CIRCLES_TITLE_NAME')"
    item-icon="house"
  >
    >
    <template v-for="(item, index) in items" #[`item-${index}`]>
      <XGrid columns="2">
        <XInput
          :placeholder="T('MAPS_STEP_CIRCLES_PLACEHOLDER_NAME')"
          :modelValue="item.options.name"
          @update:modelValue="setLayerOptions({id: item.id, name: $event})"
        />
        <XInput
          :placeholder="T('MAPS_STEP_CIRCLES_PLACEHOLDER_DESCRIPTION')"
          :modelValue="item.options.description"
          @update:modelValue="setLayerOptions({id: item.id, description: $event})"
        />
        <XInput
          :placeholder="T('MAPS_STEP_CIRCLES_PLACEHOLDER_RADIUS')"
          type="number"
          :modelValue="String(item.options.radius)"
          @update:modelValue="setLayerOptions({id: item.id, radius: $event}), optionalZoom({group: item.group})"
          :unit="T('UNIT_METER')"
        />
        <XColorPicker
          :label="T('MAPS_STEP_CIRCLES_LABEL_COLOR')"
          :modelValue="item.options.fillColor"
          @change="setLayerOptions({id: item.id, fillColor: $event})"
        />
      </XGrid>
    </template>
  </MapStepBase>
</template>

<script>
import {mapMutations, mapActions} from 'vuex';
import {LayerGroupType} from '../models';
import MapStepBase from './MapStepBase.vue';
import {T} from '@dnx/core';

export default {
  name: 'MapStepCircles',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      LayerGroupType,
    };
  },
  created() {
    this.T = T;
  },
  methods: {
    ...mapMutations('maps', ['setLayerOptions', 'addCircle']),
    ...mapActions('maps', ['optionalZoom', 'flyToToolType']),
  },
  components: {MapStepBase},
};
</script>
