import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-b700456e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "step-outer-container" };
const _hoisted_2 = { class: "step-items" };
const _hoisted_3 = { class: "step" };
const _hoisted_4 = { class: "map-preview" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XButton = _resolveComponent("XButton");
    const _component_XInput = _resolveComponent("XInput");
    const _component_XGrid = _resolveComponent("XGrid");
    const _component_XHeaderBox = _resolveComponent("XHeaderBox");
    const _component_MapStepMarkers = _resolveComponent("MapStepMarkers");
    const _component_MapStepCircles = _resolveComponent("MapStepCircles");
    const _component_MapStepDistance = _resolveComponent("MapStepDistance");
    const _component_MapStepAmenities = _resolveComponent("MapStepAmenities");
    const _component_MapStepCustomerData = _resolveComponent("MapStepCustomerData");
    const _component_MapWidget = _resolveComponent("MapWidget");
    const _component_XConfirm = _resolveComponent("XConfirm");
    const _component_XWrapper = _resolveComponent("XWrapper");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_XHeaderBox, {
            headerTitle: _ctx.mapName ? _ctx.mapName : $setup.T('MAPS_CREATE_ADD_MAP'),
            titleColor: "var(--module-maps, #00B866)",
            iconName: "map",
            iconColor: "default",
            iconBackground: "var(--module-maps, #00B866)"
        }, {
            rightButtonSlot: _withCtx(() => [
                ($options.canDelete)
                    ? (_openBlock(), _createBlock(_component_XButton, {
                        key: 0,
                        color: "error",
                        icon: "delete",
                        onClick: _cache[0] || (_cache[0] = () => $setup.deleteMap(_ctx.map.id))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.T('DELETE_MAP')), 1)
                        ]),
                        _: 1
                    }))
                    : _createCommentVNode("", true),
                _createVNode(_component_XButton, {
                    variant: "outlined",
                    icon: "clear",
                    onClick: _cache[1] || (_cache[1] = ($event) => ($options.close()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.T('MAPS_CREATE_CLOSE_BUTTON')), 1)
                    ]),
                    _: 1
                }),
                _createVNode(_component_XButton, {
                    color: "success",
                    icon: "check-circle",
                    onClick: _cache[2] || (_cache[2] = ($event) => ($options.save()))
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.T('MAPS_CREATE_SAVE_BUTTON')), 1)
                    ]),
                    _: 1
                })
            ]),
            bottomSlot: _withCtx(() => [
                _createVNode(_component_XGrid, {
                    columns: "6",
                    padding: "md"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_XInput, {
                            placeholder: $setup.T('MAPS_CREATE_MAP_NAME_PLACEHOLDER'),
                            modelValue: _ctx.mapName,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (_ctx.setMapName($event)))
                        }, null, 8, ["placeholder", "modelValue"]),
                        _createVNode(_component_XInput, {
                            placeholder: $setup.T('MAPS_CREATE_MAP_DESCRIPTION_PLACEHOLDER'),
                            modelValue: _ctx.mapDescription,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => (_ctx.setMapDescription($event)))
                        }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["headerTitle"]),
        _createVNode(_component_XWrapper, null, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_MapStepMarkers, {
                                ref: "markers",
                                items: $options.markers
                            }, null, 8, ["items"]),
                            _createVNode(_component_MapStepCircles, {
                                ref: "circles",
                                items: $options.circles
                            }, null, 8, ["items"]),
                            _createVNode(_component_MapStepDistance, {
                                ref: "isochrones",
                                items: $options.isochrones
                            }, null, 8, ["items"]),
                            _createVNode(_component_MapStepAmenities, {
                                ref: "amenities",
                                items: $options.amenities
                            }, null, 8, ["items"]),
                            _createVNode(_component_MapStepCustomerData, {
                                ref: "customerData",
                                items: $options.customerData
                            }, null, 8, ["items"])
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_MapWidget, {
                            mapId: $options.mapId,
                            onClickOnLayer: $options.clickOnLayer
                        }, null, 8, ["mapId", "onClickOnLayer"])
                    ])
                ]),
                _createVNode(_component_XConfirm, {
                    confirmIconName: "clear",
                    displayCancelIcon: false,
                    confirmTitle: $setup.T('MAPS_CREATE_CONFIRM_TITLE'),
                    confirmMessage: $setup.T('MAPS_CREATE_CONFIRM_MESSAGE'),
                    confirmButton: $setup.T('MAPS_CREATE_CLOSE_BUTTON'),
                    cancelButton: $setup.T('MAPS_CREATE_CONFIRM_CANCEL'),
                    displayConfirmBox: $data.displayConfirmBox
                }, null, 8, ["confirmTitle", "confirmMessage", "confirmButton", "cancelButton", "displayConfirmBox"])
            ]),
            _: 1
        })
    ], 64));
}
