<template>
  <XButton size="medium" id="map-close-icon" @click="closePreviewMap()">
    <XIcon name="clear" />
  </XButton>

  <!-- Display fullscreen for the preview -->
  <MapWidget :showMarkerAddOption="false" />
</template>

<script>
import {mapActions, mapMutations} from 'vuex';

export default {
  name: 'PreviewMap',
  created() {
    //Id should theoretically always have a numeric value
    let id = this.$route.params && this.$route.params.id;
    id = !isNaN(parseInt(id)) ? parseInt(id) : undefined;

    this.trackEntityView({
      module: 'Maps',
      route: this.$route,
      metadata: {id: id},
    });
  },
  methods: {
    ...mapActions({
      trackEntityView: 'tracking/trackEntityView',
    }),
    ...mapMutations('maps', ['resetMap']),
    closePreviewMap() {
      this.resetMap();
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
#map-close-icon {
  position: absolute;
  top: 1rem;
  left: 50%;
  z-index: 19;
}

:deep(.mapcontainer) {
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 18;
  border-radius: 0;
}
</style>
