<template>
  <MapStepBase
    :items="items"
    :title="T('MAPS_AMENITIES')"
    :zoom="() => flyToToolType(LayerGroupType.Amenities)"
    :add="() => addAmenity()"
    :zoom-group-tooltip="T('MAPS_AMENITIES_ZOOMTO_ALL')"
    :zoom-tooltip="T('MAPS_AMENITIES_ZOOMTO')"
    :add-tooltip="T('MAPS_AMENITIES_ADD')"
    :delete-tooltip="T('MAPS_AMENITIES_DELETE_BUTTON')"
    :visibility-tooltip="T('MAPS_AMENITIES_VISIBILITY')"
    :item-placeholder-title="T('MAPS_AMENITIES_NAME')"
    item-icon="shopping-cart"
  >
    <template v-for="(amenity, index) in items" #[`item-${index}`]>
      <XGrid columns="2">
        <XInput
          :placeholder="T('MAPS_AMENITIES_PLACEHOLDER')"
          :modelValue="amenity.options.name"
          @update:modelValue="setLayerOptions({id: amenity.id, name: $event})"
        />
        <XInput
          :placeholder="T('MAPS_AMENITIES_PLACEHOLDER_DESCRIPTION')"
          :modelValue="amenity.options.description"
          @update:modelValue="setLayerOptions({id: amenity.id, description: $event})"
        />
        <XSelect
          :clearable="true"
          :options="[
            {label: T('MAPS_AMENITIES_PRIMARY_EDUCATION'), value: 'primary_education'},
            {label: T('MAPS_AMENITIES_SECONDARY_EDUCATION'), value: 'secondary_education'},
            {label: T('MAPS_AMENITIES_BAR_PUB_CAFE'), value: 'bar_pub_cafe'},
            {label: T('MAPS_AMENITIES_RESTAURANT'), value: 'restaurant'},
            {label: T('MAPS_AMENITIES_SHOPS_ALL'), value: 'shops_all'},
            {label: T('MAPS_AMENITIES_SHOPS_DEPARTMENT_STORE'), value: 'shops_department_store'},
            {label: T('MAPS_AMENITIES_SHOPS_SUPERMARKET'), value: 'shops_supermarket'},
            {label: T('MAPS_AMENITIES_SHOPS_MALL'), value: 'shops_mall'},
            {label: T('MAPS_AMENITIES_SHOPS_WHOLESALE'), value: 'shops_wholesale'},
            {label: T('MAPS_AMENITIES_MEDICAL'), value: 'medical'},
            {label: T('MAPS_AMENITIES_DENTIST'), value: 'dentist'},
            {label: T('MAPS_AMENITIES_PHARMACY'), value: 'pharmacy'},
            {label: T('MAPS_AMENITIES_EMERGENCY_SERVICES'), value: 'emergency_services'},
            {label: T('MAPS_AMENITIES_HOTELS'), value: 'hotels'},
            {label: T('MAPS_AMENITIES_CAMPINGS'), value: 'camping'},
          ]"
          :placeholder="T('MAPS_AMENITIES_PLACEHOLDER_MARKER_TYPE')"
          :modelValue="amenity.options.amenityType"
          @update:modelValue="setLayerOptions({id: amenity.id, amenityType: $event})"
        />
      </XGrid>
    </template>
  </MapStepBase>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {IconSize} from '../models';
import {LayerGroupType} from '../models';
import MapStepBase from './MapStepBase.vue';
import {T} from '@dnx/core';

export default {
  name: 'MapStepAmenities',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      layers: [],
      LayerGroupType,
      IconSize,
    };
  },
  created() {
    this.T = T;
  },
  methods: {
    ...mapActions({
      flyToGroup: 'maps/flyToGroup',
      flyToToolType: 'maps/flyToToolType',
    }),
    ...mapMutations({
      setLayerOptions: 'maps/setLayerOptions',
      addAmenity: 'maps/addAmenity',
      updateAmenity: 'maps/updateAmenity',
    }),
  },
  components: {MapStepBase},
};
</script>
