import 'systemjs-webpack-interop/auto-public-path';

import {lifecycle} from './app.js';
import {routes} from './router.js';
import {Overview} from './pages';
import {wire} from './overview';

// singleSpa & mountParcel also in props
function createManifest() {
  const {name, version, description, dnx} = require('../package.json');
  const defaults = {
    color: '#ff0000',
    icon: 'gear',
  };

  let manifest = {
    name,
    version,
    description,
    ...defaults,
    ...dnx,
  };
  return manifest;
}

const manifest = createManifest();
export default manifest;

const bridge = {
  name: manifest.name,
  bootstrap: async props => {
    const {name, singleSpa} = props;
  },
  mount: async props => {
    const {interop} = props;
    interop?.register(bridge);
  },
  unmount: async props => {
    const {interop} = props;
    interop?.unregister(bridge);
  },
};

const core = {
  mount: async props => {
    const {name, mountParcel, customProps} = props;
    for (const [key, value] in customProps) manifest[key] = value;
    manifest.csscolor = `var(--module-${manifest.name}, ${manifest.color})`;
    await mountParcel(bridge, {domElement: document.body, interop: Window.interop});

    // delay signaling until shell is ready to receive
    Window._shellReady.then(() => {
      document.dispatchEvent(
        new CustomEvent('dnx:app-loaded', {
          detail: {
            appmodule: name,
            config: {
              component: Overview,
              routes,
            },
          },
        })
      );
    });

    wire();
  },
};

export const bootstrap = [lifecycle.bootstrap];

// Mount is called once when active is truthy, after that application should handle route/hashchange/popstate
export const mount = [core.mount, lifecycle.mount];
export const unmount = [lifecycle.unmount];
export const update = [core.update, lifecycle.update];
