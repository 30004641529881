import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_XInput = _resolveComponent("XInput");
    const _component_XSelect = _resolveComponent("XSelect");
    const _component_XMultiSelect = _resolveComponent("XMultiSelect");
    const _component_XGrid = _resolveComponent("XGrid");
    const _component_XIcon = _resolveComponent("XIcon");
    const _component_XButton = _resolveComponent("XButton");
    const _component_XStack = _resolveComponent("XStack");
    const _component_MapStepBase = _resolveComponent("MapStepBase");
    return (_openBlock(), _createBlock(_component_MapStepBase, {
        items: $props.items,
        title: _ctx.T('MAPS_STEP_DISTANCE_TITLE'),
        zoom: () => _ctx.flyToToolType($data.LayerGroupType.Iso),
        add: () => _ctx.addIsochrone(_ctx.tooltypeVal),
        "zoom-group-tooltip": _ctx.T('MAPS_STEP_DISTANCE_ZOOMTO_ALL'),
        "zoom-tooltip": _ctx.T('MAPS_STEP_DISTANCE_ZOOMTO'),
        "add-tooltip": _ctx.T('MAPS_STEP_DISTANCE_ADD'),
        "delete-tooltip": _ctx.T('MAPS_STEP_DISTANCE_BUTTON_DELETE'),
        "visibility-tooltip": _ctx.T('MAPS_STEP_DISTANCE_VISIBILITY'),
        "item-placeholder-title": _ctx.T('MAPS_STEP_DISTANCE_TITLE_NAME'),
        "item-icon": "clock"
    }, _createSlots({ _: 2 }, [
        _renderList($props.items, (geoJson, index) => {
            return {
                name: `item-${index}`,
                fn: _withCtx(() => [
                    _createVNode(_component_XStack, {
                        direction: "vertical",
                        gap: "md"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_XGrid, { columns: "2" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_XInput, {
                                        placeholder: _ctx.T('MAPS_STEP_MARKERS_PLACEHOLDER_NAME'),
                                        modelValue: geoJson.options.name,
                                        "onUpdate:modelValue": ($event) => (_ctx.setLayerOptions({ id: geoJson.id, name: $event }))
                                    }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_XInput, {
                                        placeholder: _ctx.T('MAPS_STEP_DISTANCE_PLACEHOLDER_DESCRIPTION'),
                                        modelValue: geoJson.options.description,
                                        "onUpdate:modelValue": ($event) => (_ctx.setLayerOptions({ id: geoJson.id, description: $event }))
                                    }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_XSelect, {
                                        clearable: true,
                                        options: [
                                            { label: _ctx.T('MAPS_STEP_DISTANCE_LABEL_WALKING'), value: $data.Transportation.walking },
                                            { label: _ctx.T('MAPS_STEP_DISTANCE_LABEL_CYCLING'), value: $data.Transportation.cycling },
                                            { label: _ctx.T('MAPS_STEP_DISTANCE_LABEL_DRIVING'), value: $data.Transportation.driving },
                                        ],
                                        placeholder: _ctx.T('MAPS_STEP_DISTANCE_PLACEHOLDER_TRANSPORT'),
                                        modelValue: geoJson.options.transport,
                                        "onUpdate:modelValue": ($event) => (_ctx.setLayerOptions({ id: geoJson.id, transport: $event }))
                                    }, null, 8, ["options", "placeholder", "modelValue", "onUpdate:modelValue"]),
                                    _createVNode(_component_XMultiSelect, {
                                        options: [
                                            { label: '5 min', value: 5, order: 0 },
                                            { label: '10 min', value: 10, order: 1 },
                                            { label: '15 min', value: 15, order: 2 },
                                            { label: '20 min', value: 20, order: 3 },
                                            { label: '25 min', value: 25, order: 4 },
                                            { label: '30 min', value: 30, order: 5 },
                                            { label: '35 min', value: 35, order: 6 },
                                            { label: '40 min', value: 40, order: 7 },
                                            { label: '45 min', value: 45, order: 8 },
                                            { label: '50 min', value: 50, order: 9 },
                                            { label: '55 min', value: 55, order: 10 },
                                            { label: '1 uur', value: 60, order: 11 },
                                        ],
                                        placeholder: _ctx.T('MAPS_STEP_DISTANCE_PLACEHOLDER_CHOOSE_TRAVEL_TIME'),
                                        modelValue: geoJson.options.minutes,
                                        "onUpdate:modelValue": ($event) => (_ctx.setLayerOptions({ id: geoJson.id, minutes: $event }))
                                    }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                            }, 1024),
                            _createVNode(_component_XButton, {
                                "component-style": "primary-action",
                                size: "small",
                                onClick: ($event) => (_ctx.fetchIsochroneGeoJson({ groupId: geoJson.group.groupId }))
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_XIcon, {
                                        name: "redo-arrow",
                                        size: "custom"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.T('MAPS_STEP_DISTANCE_CALCULATE_TIME')), 1)
                                ]),
                                _: 2
                            }, 1032, ["onClick"])
                        ]),
                        _: 2
                    }, 1024)
                ])
            };
        })
    ]), 1032, ["items", "title", "zoom", "add", "zoom-group-tooltip", "zoom-tooltip", "add-tooltip", "delete-tooltip", "visibility-tooltip", "item-placeholder-title"]));
}
