import {format, OverviewProvider, T} from '@dnx/core';
import RouterLink from 'vue-router';
import {h} from 'vue';
import {useDelete} from './composables';

export function wire() {
  OverviewProvider.registerModuleConfiguration('maps', {
    badge: {name: 'map', color: 'var(--module-maps, #00B866)'},
    searchableProperties: ['name'],
  });

  // enkele callback registreren voor module
  OverviewProvider.registerRecordConfigurationProvider('maps', map => {
    const deleteFlow = useDelete();

    return {
      description: `${T('MAPS_OVERVIEW_LAST_PUBLISHED')} ${format(map.modified, 'date')}`,

      contextMenuOptions: [
        {
          link: `/maps/preview/${map.id}`,
          icon: {name: 'play'},
          label: T('MAPS_OVERVIEW_PREVIEW'),
        },
        {
          handler: () => deleteFlow(map),
          icon: {name: 'delete', color: 'var(--button-danger, #D21460)'},
          label: T('DELETE'),
        },
      ],
    };
  });
}
